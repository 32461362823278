<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">视频ID：</span>
        <el-input class="width_150" v-model="search.id" placeholder="请输入ID" size="medium" clearable></el-input>
      </div>
<!--      <div class="search-box">-->
<!--        <span class="search-box-title">账号：</span>-->
<!--        <el-input v-model="search.username" placeholder="请输入账号" size="medium" clearable></el-input>-->
<!--      </div>-->
      <div class="search-box">
        <span class="search-box-title">举报类型：</span>
        <el-select v-model="search.type">
          <el-option v-for="(item,index) in types" :key="index" :value="index" :label="item"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">时间：</span>
        <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="YYYY-MM-DD"></el-date-picker>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="video_id" label="视频ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="video_title" label="视频标题" align="center" :show-overflow-tooltip="true"></el-table-column>
<!--      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="report_type" label="标题" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{types[scope.row.report_type]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="describe" label="描述" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="addtime" label="举报时间" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

  </div>
</template>

<script>
import page from "@/components/page";
import {list} from "@/api/short_video/report.js"
export default {
  name: "shortVideoList",
  components: {
    page,
  },
  data() {
    return {
      types:[
        "全部","广告内容","引人不适","未成年人不当行为","涉嫌非法", "疑似/教唆自残自杀", "诱导点赞、分享、关注","其他"
      ],
      pager: { total: 0, page: 1, rows: 20 },
      search: {
        //搜索
        id: "", //id
        type:null,
        searchDate: [], //时间
      },
      tableData: [
        // {id:1,report_type:1,describe:"举报内容",username:"我是举报账号",video_title:"我是视频标题",uid:1,video_id:2,addtime:"2022-01-01 00-00-00"}
      ], //数据
    };
  },
  computed: {},
  created() {
  },
  mounted() {
    this.search.id = this.$route.params["id"]
    this.getList()
  },
  methods: {
    getList(){
      var data = [
        {key:"page",val:this.pager.page},
        {key:"row",val:this.pager.row},
        {key:"id",val:this.search.id},
        {key:"type",val:this.search.type},
        {key:"begin_time",val:this.search.searchDate && this.search.searchDate.length>0?this.search.searchDate[0] + " 00:00:00":""},
        {key:"end_time",val:this.search.searchDate && this.search.searchDate.length>0?this.search.searchDate[1] + " 23:59:59":""},
      ]
      list(data).then((res)=>{
        if(res.code == 0){
          this.tableData = res.data.list
          if(this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    }
  },
};
</script>

<style scoped>
.width_80{
  width: 80px !important;
}
.width_100{
  width: 100px !important;
}
.width_120{
  width: 120px !important;
}
.width_150{
  width: 150px !important;
}
.width_200{
  width: 200px !important;
}
.width_250{
  width: 250px !important;
}
</style>